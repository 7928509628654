<template lang="pug">
sidebar-accordion(:title="$t('spacing')")
  om-padding(
    v-if="showPadding"
    propsProperty="selectedElement"
    :typeOfComponent="typeOfComponent"
    :showAccordion="false"
  )
  om-margin(
    v-if="showMargin"
    propsProperty="selectedElement"
    :typeOfComponent="typeOfComponent"
    :showAccordion="false"
  )
  om-z-index(v-if="showZIndex" property="zIndex" :showAccordion="false")
</template>
<script>
  import zIndex from '@/editor/components/sidebar/inputs/zIndex.vue';

  export default {
    components: { 'om-z-index': zIndex },
    props: {
      typeOfComponent: { type: String, required: true },
      showPadding: { type: Boolean, default: true },
      showMargin: { type: Boolean, default: true },
      showZIndex: { type: Boolean, default: true },
    },
  };
</script>
